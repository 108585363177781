import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import { saveAs } from 'file-saver';
import React, { useState } from "react";

import {
  Card,
  Form,
  FormGroup,
  Input,
  Table,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import background from "../../../assets/img/header-background.jpg";
import 'assets/css/rsuite-default.min.css';
import axios from 'axios';

class ConsumerInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      details: [],
      instance: [],
      workflow: [],
      fileUpload: null,
      steps: [],
      user: [],
      files: [],
      consumerFiles: [],
      receiverFiles: [],
      replies: [],
      extra: [],
      answerStep: '',
      answerAction: '',
      answer: '',
      filesUpload: [],
      showAnswer: 0,
      hasReplies: 0,
      maxlimitReplies: false,
      loading: true,
      blocked: 0,
      declinedbyConsumer: false
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    // console.log(handle);

    if (localStorage.getItem('token')) {
      const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle + "/?callcenter=true", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          this.setState({
            instance: res.data,
            details: res.data
          });

          console.log(this.state.instance);

          if (this.state.instance.Status > 0)
            this.setState({
              step: this.state.instance.Status - 1
            });
          else {
            this.setState({
              step: 3
            });
          }

          if (this.state.instance.Status == 4)
            this.setState({
              blocked: 1
            });

          if (this.state.instance.Status == 5 && this.state.instance.Declined == true) {
            this.setState({
              declinedbyConsumer: true
            })

            console.log(this.state.declinedbyConsumer);
          }
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/extra/" + handle + "/?callcenter=true", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              extra: result,
            });
          },
          (error) => {
            // console.log(error);
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              user: result,
            });
          },
          (error) => {
            // console.log(error);
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              files: result,
              loading: false
            });
            let allFiles = this.state.files
            let filteredConsumerFiles = allFiles.filter(element => {
              if (element.UserId == this.state.instance.UserId) return element
            })
            let filteredReceiverFiles = allFiles.filter(element => {
              if (element.UserId != this.state.instance.UserId) return element
            })
            this.setState({
              consumerFiles: filteredConsumerFiles,
              receiverFiles: filteredReceiverFiles
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )
    };
  }

  downloadAllFiles = (files) => {
    files.forEach(f => {
      fetch(f.Link, {
        headers: {
          'Content-Type': f.Type
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, f.FileName);
        });
    })
  };

  handleDownload = (link, contentType, name) => {
    fetch(link, {
      headers: {
        'Content-Type': contentType
      }
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, name);
      });
  };

  GiveAnswer = stepStatus => {
    this.setState({
      showAnswer: 1
    });

    fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/declineresponse/" + this.state.instance.Id + '/', {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(data => {
        // console.log(data);
      });
  }

  updateAnswer(evt) {
    // console.log(evt.target.value);
    this.setState({
      answer: evt.target.value
    })
  }

  acceptAnswer() {
    fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/acceptresponse/" + this.state.instance.Id + '/', {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(data => {
        window.location.replace("/consumer/index/");
      });
  }

  alisverisiRejected() {
    fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/declineanswer/" + this.state.instance.Id + '/', {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        this.setState({
          blocked: 1
        })
        window.location.reload(false);
      });
  }

  sendAnswer(evt) {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/replies/new/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'InstanceId': this.state.instance.Id,
          'UserType': 0,
          'Message': this.state.answer
        })
      })
        .then(data => {
          fetch(process.env.REACT_APP_API_LINK + "/api/replies/all/" + this.state.instance.Id + "/", {
            method: 'get',
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
            .then(res => res.json())
            .then(
              (result) => {
                // console.log(result);
                this.setState({
                  replies: result.replies,
                  showAnswer: 1,
                  hasReplies: 1,
                  maxlimitReplies: result.maxlimit
                });

                var counter = 0;
                result.replies.forEach(function (arrayItem) {
                  arrayItem.user = result.users[counter];
                  counter++;

                });
                this.setState({
                  replies: result.replies,
                });


              },
              (error) => {
                // console.log(error);
                this.setState({
                  error
                });
              }
            )
        })
    }
  }

  updateFile(evt) {
    this.setState({ fileUpload: evt.target.files[0] });
  }

  deleteFile = fileId => {
    if (localStorage.getItem('token')) {
      axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          axios.get(process.env.REACT_APP_API_LINK + "/api/files/instance/" + this.state.instance.Id + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
          })
            .then(res => {
              this.setState({
                loading: false,
                files: res.data
              });
              // console.log(res);
            })
        })
    }
  }

  uploadFile(evt) {
    // this.setState({ fileUpload: evt.target.files[0] });
    const formData = new FormData();
    // var res = evt.split("/");

    // Update the formData object 
    formData.append(
      "files",
      evt.target.files[0]
    );

    // Details of the uploaded file 
    // console.log(evt.target.files[0]);
    // console.log(formData);

    fetch(process.env.REACT_APP_API_LINK + "/api/files/answer/" + this.state.instance.Id + "/", {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then((result) => {
        this.setState({
          answerStep: result.step,
          answerAction: result.action
        });

        fetch(
          process.env.REACT_APP_FILEMANAGER_LINK,
          {
            method: 'POST',
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log('Success:', result);

            fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
              method: 'POST',
              headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg',
              }),
              body: JSON.stringify({
                'Name': result[0].name,
                'Path': result[0].path,
                'Size': result[0].size,
                'Type': result[0].type,
                'InstanceId': this.state.instance.Id,
                'StepId': this.state.answerStep,
                'ActionId': this.state.answerAction
              })
            })
              .then(data => {
                // console.log(data);

                axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                  // headers: { Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg'}
                })
                  .then(res => {
                    this.setState({
                      loading: false,
                      files: res.data
                    });
                    let allFiles = this.state.files
                    let filteredConsumerFiles = allFiles.filter(element => {
                      if (element.UserId == this.state.instance.UserId) return element
                    })
                    let filteredReceiverFiles = allFiles.filter(element => {
                      if (element.UserId != this.state.instance.UserId) return element
                    })
                    this.setState({
                      consumerFiles: filteredConsumerFiles,
                      receiverFiles: filteredReceiverFiles
                    });
                    // console.log(res);
                  })

                fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + this.state.instance.Id + '/', {
                  method: 'get',
                  headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                  })
                })
                  .then(res => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        files: result,
                        loading: false
                      });
                      let allFiles = this.state.files
                      let filteredConsumerFiles = allFiles.filter(element => {
                        if (element.UserId == this.state.instance.UserId) return element
                      })
                      let filteredReceiverFiles = allFiles.filter(element => {
                        if (element.UserId != this.state.instance.UserId) return element
                      })
                      this.setState({
                        consumerFiles: filteredConsumerFiles,
                        receiverFiles: filteredReceiverFiles
                      });
                    },
                    (error) => {
                      this.setState({
                        error
                      });
                    }
                  )
              })
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      });
  }

  acceptInstance = () => {

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + `/api/workflowinstances/callcenter/accept/${this.state.instance.Id}`, {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          window.location.replace("/admin/callcenter/");
        })
    }
  }


  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <InstanceHeader />
          {/* <div className="header pb-4" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}> */}
          <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Υποβολή Αιτήματος
                            </span>
                            <CardBody>
                              <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Αριθμός Αιτήματος: </span> &nbsp; CallCenter-{this.state.instance.SerialNumber}</Row>
                              <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Υποβολής: </span> &nbsp; {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt--4" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Πορεία Αιτήματος</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                        {(this.state.instance.Status == 3 && this.state.user.PersonRole == 'Admin') ?
                          <>
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => { this.acceptInstance() }}
                            >
                              Αποδοχή και Ολοκλήρωση
                            </Button>
                          </> : <></>
                        }
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Steps current={this.state.step}>
                        <Steps.Item title="Υποβολή Αιτήματος" description="Το αίτημα υποβλήθηκε." />
                        <Steps.Item title="Διαχείριση αιτήματος" description="Λήψη και έλεγχος του αιτήματός σας." />
                        <Steps.Item title="Ολοκλήρωση Αίτηματος" description="Αίτημα προς Ολοκλήρωση" />
                      </Steps>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col className="order-xl-1" xl={'12'}>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h4 className="mb-0">Στοιχεία Αιτήματος</h4>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* <Button
                            color="primary"
                            href={"/admin/instance/" + this.state.details.Id}
                            size="sm"
                          >
                            Βήματα αιτήματος
                          </Button> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Όνομα Κατόχου
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Όνομα Κατόχου"
                                defaultValue={this.state.extra.name}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Επίθετο Κατόχου
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Επίθετο Κατόχου"
                                defaultValue={this.state.extra.last}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                ΑΦΜ Κατόχου
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="ΑΦΜ Κατόχου"
                                defaultValue={this.state.extra.afm}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Αριθμός Παροχής
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Αριθμός Παροχής"
                                defaultValue={this.state.extra.num}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατηγορία Αιτήματος
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.extra.category}
                                placeholder="Κατηγορία"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Είδος Ενέργειας
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Είδος Ενέργειας"
                                defaultValue={this.state.extra.energy}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Είδος Παραλήπτη
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Είδος Παραλήπτη"
                                defaultValue={this.state.extra.typercvr}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Παραλήπτης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Παραλήπτης"
                                defaultValue={this.state.extra.rcvr}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κινητό Τηλέφωνο
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Κινητό"
                                defaultValue={this.state.instance.CellPhoneNumber}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Σταθερό Τηλέφωνο
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Σταθερό"
                                defaultValue={this.state.instance.LandLineNumber}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατάσταση Αιτήματος
                              </label>
                              {this.state.details.Status == 1 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='1. Υποβολή Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 2 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='2. Επεξεργασία Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 3 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='3. Αίτημα προς Ολοκλήρωση'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 4 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='4. Απευθείας Απόρριψη'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 5 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='5. Ολοκλήρωση Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <h6 className="heading-small text-muted mb-4">
                              Σχολια/Παρατηρησεις Αιτηματος
                            </h6>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={this.state.extra.text}
                              placeholder="Σχόλια/Παρατηρήσεις Αιτήματος"
                              type="textarea"
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        {this.state.instance.Status > 2 ?
                          <>
                            < hr />
                            <Row>
                              <Col md="12">
                                <h6 className="heading-small text-muted mb-2">
                                  Απαντηση Προμηθευτη / Διαχειριστη
                                </h6>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.extra.reply}
                                  placeholder="Απάντηση Παραλήπτη"
                                  type="textarea"
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                          </> : <></>
                        }

                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Εγγραφα Καταναλωτη
                            </h6>
                          </Col>
                          {this.state.consumerFiles.length > 0 &&
                            <>
                              <Col md="6">
                                <Button
                                  color="success"
                                  size='sm'
                                  onClick={() => { this.downloadAllFiles(this.state.consumerFiles) }}
                                >
                                  Λήψη όλων
                                </Button>
                              </Col>
                            </>}

                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Ονομα αρχειου</th>
                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                <th scope="col">Ενεργειες</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.consumerFiles.map((dynamicFiles) =>
                                  <tr>
                                    <td>{dynamicFiles.FileName}</td>
                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                    <td>
                                      <Button
                                        color="primary"
                                        href={dynamicFiles.Link}
                                        target="_blank"
                                        title="Προβολή"
                                        className="btn-sm btn-primary"
                                      >
                                        <i className="fas fa-eye" />
                                      </Button>
                                      <Button
                                        onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                        color="success"
                                        size='sm'
                                        title="Λήψη"
                                      >
                                        <i className="fas fa-download" />
                                      </Button>
                                      {this.state.user.PersonRole == 'Admin' ?
                                        <>
                                          <Button
                                            color="danger"
                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                            size="sm"
                                            title="Διαγραφή"
                                          >
                                            <i className="fas fa-trash" />
                                          </Button>
                                        </> : <> </>}
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        </Row>
                      </div>

                      <br />
                      <br />
                      <div className="pl-lg-4" style={{ width: '100%' }}>
                        <Row>
                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Εγγραφα Παραληπτη
                            </h6>
                          </Col>
                          {this.state.receiverFiles.length > 1 &&
                            <>
                              <Col md="6">
                                <Button
                                  color="success"
                                  size='sm'
                                  onClick={() => { this.downloadAllFiles(this.state.receiverFiles) }}
                                >
                                  Λήψη όλων
                                </Button>
                              </Col>
                            </>}

                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Ονομα αρχειου</th>
                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                <th scope="col">Ενεργειες</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.receiverFiles.map((dynamicFiles) =>
                                  <tr>
                                    <td>{dynamicFiles.FileName}</td>
                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                    <td>
                                      <Button
                                        color="primary"
                                        href={dynamicFiles.Link}
                                        target="_blank"
                                        title="Προβολή"
                                        className="btn-sm btn-primary"
                                      >
                                        <i className="fas fa-eye" />
                                      </Button>
                                      <Button
                                        onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                        color="success"
                                        size='sm'
                                        title="Λήψη"
                                      >
                                        <i className="fas fa-download" />
                                      </Button>
                                      {this.state.user.PersonRole == 'Admin' ?
                                        <>
                                          <Button
                                            color="danger"
                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                            size="sm"
                                            title="Διαγραφή"
                                          >
                                            <i className="fas fa-trash" />
                                          </Button>
                                        </> : <> </>}
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>


            </Row>
          </Container>
        </>
      );
    }
  }
}

export default ConsumerInstance;
