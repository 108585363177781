import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import dedaBackground from "../../assets/img/rae-bg-consumers.jpg";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';

import FacebookLogin from 'react-facebook-login';
import { FacebookLoginButton } from "react-social-login-buttons";

import '../../assets/css/login.css';

import GoogleLogin from 'react-google-login';

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_API_LINK + '/api/auth/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}



export default function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [erroremail, setErrorEmail] = useState(false);
  const [errorpass, setErrorPass] = useState(false);
  const [platform, setPlatform] = useState("callcenter");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const [show, setShow] = useState(false);

  // // console.log(email);
  // // console.log(password);
  const handleClose = () => {
    setShowModal(false);
    window.location.replace("/admin/index");
  };

  const handleSubmit = async e => {
    // // console.log(email);
    // // console.log(password);

    e.preventDefault();
    const auth = await loginUser({
      email,
      password,
      platform
    });



    if (auth.detail == "Incorrect username or password") {
      // // console.log("Incorrect email and/or password");
      setErrorEmail(true);
    }
    // The below does not work

    if (auth.errors) {
      setErrorEmail(false);
      setErrorPass(false);
      if (auth.errors == 'Email not exists') {
        alert('caught wrong email');
        setErrorEmail(true);
        localStorage.removeItem('token');
        // window.location.replace("/");
      }
      if (auth.errors == 'Password Incorrect') {
        alert('caught wrong pass');
        setErrorPass(true);
        localStorage.removeItem('token');
        // window.location.replace("/");
      }
    };
    if (auth.access_token) {
      setToken(String(auth.access_token));
      // // console.log(auth.access_token);
      window.location.replace("/admin/index");
    };

  }





  return (
    <div className="main-content" style={{ backgroundImage: `url(${dedaBackground})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", backgroundAttachment: "fixed", height: "100vh" }}>
      <AuthNavbar />
      <div className="header py-7 py-lg-7" ></div>

      <Container className="mt--8 pb-5">


        <Row className="justify-content-center">

          <Col lg="5" md="7" >
            <Card className="shadow border-0" style={{ backgroundColor: "rgba(183,180,183,0.6)" }}>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="text" onChange={e => setEmail(e.target.value)} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Κωδικός" type="password" onChange={e => setPassword(e.target.value)} />
                    </InputGroup>
                  </FormGroup>
                  {/* <a
                    className="text-light"
                    href={"/forgotpassword/index"}
                  // onClick={e => e.preventDefault()}
                  >
                    <small style={{ textAlign: 'center', color: '#000' }}>Ξεχάσατε τον κωδικό σας ;</small>
                  </a>
                  <div>
                    <a className="text-light" href="https://www.rae.gr/terms-of-use-myrae/" target="_blank" rel="noopener noreferrer">
                      <small style={{ textAlign: 'center', color: 'white' }}> Όροι</small>
                    </a>
                    <small style={{ textAlign: 'center', color: '#000' }}> και</small>
                    <a className="text-light" href="https://www.rae.gr/processing-of-personal-data-myrae/" target="_blank" rel="noopener noreferrer">
                      <small style={{ textAlign: 'center', color: 'white' }}> Προϋποθέσεις</small>
                    </a>
                  </div> */}
                  {erroremail ? <p style={{ color: 'orange' }}>To email ή ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε δοκιμάστε ξανά.</p> : <></>}
                  {errorpass ? <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="text" style={{ color: 'red' }} /> : <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="hidden" style={{ color: 'red' }} />}

                  <div className="text-center">
                    <Button className="my-3" color="primary" type="submit" style={{ width: '180px' }}>
                      Σύνδεση
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>

        </Row>


      </Container>
    </div>


  )
}


Login.propTypes = {
  setToken: PropTypes.func.isRequired
}