import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../../assets/css/datatable.css';

class ConsumerIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: []
    };
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          // console.log(result);
          this.setState({
            userDetails: result
          });
        })

      axios.get(process.env.REACT_APP_API_LINK + "/api/consumer/instances/callcenter/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          // console.log(res.data);

          var WorkflowInstance_Table = [];

          res.data.forEach((item) => {
            WorkflowInstance_Table.push(item);
          });

          // var myuser = this.state.user.PersonRole;
          const instances2 = WorkflowInstance_Table;
          instances2.forEach(function (part, index) {
            this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
            var dateArray = ((this[index].StartedAt).toString()).split('T');
            var timeArray = dateArray[1].split(':');
            this[index].StartedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
            // this[index].Receiver = this[index].Receiver[0];
            try {
              this[index].CategoryData = this[index].CategoryData.split('/')[0];
            } catch (error) {
              console.error("Error occurred:", error);
              // Handle the error or set a default value
              this[index].CategoryData = ""; // or some default value
            }
            this[index].Buttons =
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/consumer/instance/" + this[index].Id}
                  size="sm"
                  title="Στοιχεία"
                >
                  <i className="fas fa-eye" />
                </Button>
              </React.Fragment>

          }, instances2);
          this.state.datas = {
            columns: [
              {
                label: 'Αριθμος Αιτησης ▼',
                field: 'SerialNumber',
                sort: 'desc',
                width: 100,
                viewColumns: false
              },
              {
                label: 'Επωνυμο ▼',
                field: 'SurnameData',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Κατάσταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Παραλήπτης ▼',
                field: 'Receiver',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Ενέργειες ▼',
                field: 'Buttons',
                sort: 'asc',
                width: 100
              },
            ],
            rows: WorkflowInstance_Table,
          };

          this.setState({
            instances: WorkflowInstance_Table
          });

        })


    };
  }



  render() {
    return (
      <>
        <GenericHeader />


        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Υποβεβλημένα αιτήματα</h3>
                    </div>
                    {this.state.userDetails.Email != "deletedusers@rae.gr" ?
                      <>
                        <div className="col text-right">
                          <Button
                            color="success"
                            size="sm"
                            href="/consumer/newinstance"
                          >
                            Νέο αίτημα
                          </Button>
                          <Button
                            color="warning"
                            href={"/consumer/historyexport"}
                            size="sm"
                          >
                            Εξαγωγή Αιτημάτων
                          </Button>
                          <Button
                            color="primary"
                            href={"/consumer/instances"}
                            size="sm"
                          >
                            Προβολή όλων

                          </Button>
                        </div>
                      </>
                      : <></>
                    }
                  </Row>
                </CardHeader>

                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        bordered
                        exportToCSV
                        hover
                        responsive
                        strip
                        data={this.state.datas}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>

                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>




          <div class='push'></div>
        </Container>
      </>
    );
  }
}

export default ConsumerIndex;
