import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import { CommonLoading } from 'react-loadingg';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import EVOControlsHelper from "../../../common/EVOControlsHelper";
import ReactTooltip from 'react-tooltip';
import { ValidatorHelper } from "../../../common/ValidatorHelper"
import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

import { Cascader } from 'antd';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images_tooltips_images = importAll(require.context('../tooltipimages/', false, /\.(png|jpe?g|svg)$/));

class newInstance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deddieElevenDigits: false,
            user: [],
            error: null,
            activeNav: 1,
            enabled: true,
            gasMaintainers: [],
            electrMaintainers: [],
            gasSuppliers: [],
            electrSuppliers: [],
            gasAndElectrSuppliers: [],
            selectedType: 0,
            selectedReceiver: 0,
            SupplierStr: '',
            SupplierStr2: '',
            wId: '',
            dropdownsActive: 1,
            instanceId: '',
            steps: [],
            files: [],
            fileUpload: null,
            instance: '',
            serialNo: null,
            instanceCategory: '',
            areAllFieldsFilled: false,
            phoneNumber: '',
            landLineNumber: '',
            country_code: ''
        };
        this.changePhoneNumber = this.changePhoneNumber.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeReceiver = this.changeReceiver.bind(this);
        this.changeSupplier = this.changeSupplier.bind(this);
        this.changeSupplier2 = this.changeSupplier2.bind(this);
        this.deleteFile = this.deleteFile.bind(this);

        this.updateInputValueCascader = this.updateInputValueCascader.bind(this);
    }

    async componentDidMount() {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {

                        this.setState({
                            isLoaded: true,
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };

        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/lists/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    gasMaintainers: res.data.gm,
                    electrMaintainers: res.data.em,
                    gasSuppliers: res.data.gs,
                    electrSuppliers: res.data.es,
                    gasAndElectrSuppliers: res.data.egs,
                    instanceId: uuidv4(),
                    instanceId2: uuidv4()
                });
            })
    };

    changePhoneNumber = (value, country) => {
        const dialCode = country.dialCode;
        const formattedNumber = value.slice(dialCode.length).replace(/\D/g, ''); // Removing non-numeric characters
        const phoneNumber = dialCode + formattedNumber;

        this.setState({
            phoneNumber: phoneNumber,
            country_code: dialCode
        });


        console.log(this.state.phoneNumber);

        this.setSubmitButtonsEnabled(true);
    };

    handleInputChange(event) {
        const { name, value } = event.target;

        // Check if the value contains only numbers or is empty before setting state
        if (/^[0-9]*$/.test(value)) {
            this.setState({
                [name]: value
            });
        }

        this.setSubmitButtonsEnabled(true);
    }



    changeType(event) {
        // console.log(event.target.value);
        this.setState({ selectedType: event.target.value });

        if (event.target.value == 3) {
            this.setState({ selectedReceiver: 2 });
        }

        if (event.target.value == 4) {
            this.state.submitButton = 1;
        }
        this.forceUpdate()

    }

    changeReceiver(event) {
        // console.log(event.target.value);
        this.setState({ selectedReceiver: event.target.value });
        console.log(this.state.selectedReceiver);
    }

    changeSupplier(event) {
        // console.log(event.target.value);
        this.setState({ SupplierStr: event.target.value });
        if (this.state.selectedReceiver == 3) {
            if (event.target.value != 0 && this.state.SupplierStr2 != 0) {
                this.state.submitButton = 1;
            }
            else {
                this.state.submitButton = 0;
            }
        } else {
            if (event.target.value != 0) {
                this.state.submitButton = 1;
            }
            else {
                this.state.submitButton = 0;
            }
        }
    }

    changeSupplier2(event) {
        // console.log(event.target.value);
        this.setState({ SupplierStr2: event.target.value });

        if (this.state.selectedReceiver == 3) {
            if (event.target.value != 0 && this.state.SupplierStr != 0) {
                this.state.submitButton = 1;
            }
            else {
                this.state.submitButton = 0;
            }
        } else {
            if (event.target.value != 0) {
                this.state.submitButton = 1;
            }
            else {
                this.state.submitButton = 0;
            }
        }
    }

    handleClick = stepStatus => {
        var wId = '';

        if (this.state.selectedType == 1) {
            if (this.state.selectedReceiver == 1) {
                // this.setState({ wId: '98ea1b9e-b0c3-4166-b390-d95ce7f6fd18' });
                wId = '98ea1b9e-b0c3-4166-b390-d95ce7f6fd18'
            }
            else if (this.state.selectedReceiver == 2) {
                // this.setState({ wId:  '000cbd3b-4e8e-42aa-a30b-ac2a7672cc6d'});
                wId = '000cbd3b-4e8e-42aa-a30b-ac2a7672cc6d'
            }
            else if (this.state.selectedReceiver == 3) {
                // this.setState({ wId:  '390f156d-f0b1-43fa-b2a7-5ee4c658fd8c'});
                wId = '390f156d-f0b1-43fa-b2a7-5ee4c658fd8c'
            }
        }
        else if (this.state.selectedType == 2) {
            if (this.state.selectedReceiver == 1) {
                // this.setState({ wId:  'fbc2cb7f-eb1c-455f-a484-579ffb53f1a3'});
                wId = 'fbc2cb7f-eb1c-455f-a484-579ffb53f1a3'
            }
            else if (this.state.selectedReceiver == 2) {
                // this.setState({ wId:  'afe2dd96-b2bd-4af2-aace-4f53ada663cf'});
                wId = 'afe2dd96-b2bd-4af2-aace-4f53ada663cf'
            }
            else if (this.state.selectedReceiver == 3) {
                // this.setState({ wId:  '22c1e5ab-14eb-4334-b532-6456aa4bdbab' });
                wId = '22c1e5ab-14eb-4334-b532-6456aa4bdbab'
            }
        }
        else if (this.state.selectedType == 3) {
            // this.setState({ wId:  '58bf4d53-51cf-4135-9615-090be3c0c9d5'});
            wId = '58bf4d53-51cf-4135-9615-090be3c0c9d5'
        }

        this.setState({ dropdownsActive: 0 });

        axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/initiliaze/" + wId + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    steps: res.data.stepsInfused,
                    fileUpload: null,
                    wId: wId
                });

                fetch(process.env.REACT_APP_API_LINK + '/api/images/getimagebyworkflowid/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        // 'WorkflowId': this.state.wId,
                        'Energy': this.state.selectedType,
                        'Receiver': this.state.SupplierStr
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        // console.log('image');
                        // console.log(data);
                        try {
                            document.getElementById("imageattooltip").innerHTML = "<img src=" + images_tooltips_images[data].default + " style='width:400px;' alt='Image'/>"
                        }
                        catch {

                        }
                    })

                this.setSubmitButtonsEnabled(false);
            })
    }

    setSubmitButtonsEnabled(isEnabled) {
        //isEnabled = true; //bug fix 20221115 hot fix to enable button
        try {
            if (isEnabled) {
                if (this.state.phoneNumber.length > 10 || this.state.landLineNumber.length > 8) {
                    document.getElementById('submit-button-1').removeAttribute('disabled');
                    document.getElementById('submit-button-2').removeAttribute('disabled');
                }
                else {
                    document.getElementById('submit-button-1').setAttribute('disabled', '');
                    document.getElementById('submit-button-2').setAttribute('disabled', '');
                }
            } else {
                document.getElementById('submit-button-1').setAttribute('disabled', '');
                document.getElementById('submit-button-2').setAttribute('disabled', '');
            }

        }
        catch (ex) {
            console.error(ex);
        }
    }

    deleteInstance = stepStatus => {
        window.location.replace("/consumer/index");
    }


    deleteFile = (fileId) => {
        var self = this;
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/initializestatus/" + this.state.instanceId + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                        .then(res => {
                            this.setState({
                                loading: false,
                                files: res.data
                            });
                        })
                })
        }
    }

    uploadFile(evt, actionid) {
        const formData = new FormData();
        var res = actionid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        // Details of the uploaded file 
        // console.log(evt.target.files[0]);
        // console.log(formData);


        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log('Success:', result);

                fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instanceId,
                        'StepId': res[0],
                        'ActionId': res[1]
                    })
                })
                    .then(data => {
                        // console.log(data);

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/initializestatus/" + this.state.instanceId + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                            })
                    })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    updateInputValue(evt) {
        console.log(this.state);
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];

        function checkifFilled(item) {
            let newTargetValue = evt.target.value ?? '';
            let stepActionId = evt.target.id.split("/")[0];
            if (stepActionId !== item.action.Id) {
                newTargetValue = item.data.Data;
            }

            if (item.data.Data !== '') {

                if (item.action.Name == 'Όνομα Κατόχου Παροχής*') {
                    if (newTargetValue.trim().length == 0) {
                        return false;
                    }
                    else {
                        return true;
                    }
                } else if (item.action.Description == 'Αριθμός Παροχής') {
                    if ((newTargetValue).length < 8) {
                        console.log('xaxa');
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.action.Target == 'afmlimit9') {
                    let value = item.data.Data;
                    let isValidAfm = ValidatorHelper.AFMValidator.IsValidAfm(value);
                    return isValidAfm;
                }
                else if (item.action.Target == 'aeriolimit14') {
                    console.log(item.data.Data)
                    if (/^\d{14}$/.test(item.data.Data) == false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else
                    return true;

            }
        }

        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '') {
                        this.setSubmitButtonsEnabled(false);
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    this.setState({ areAllFieldsFilled: true }, () => {
                        this.setSubmitButtonsEnabled(true);
                    });
                }
                else {
                    this.setSubmitButtonsEnabled(false);
                }
            }
        }

    }

    updateInputValueNumber(evt, dynamicactions) {
        if (dynamicactions.action.Target == 'limit14') {
            if ((evt.target.value).length > 14) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }
        }

        if (dynamicactions.action.Target == 'limit9') {
            if ((evt.target.value).length > 9) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }
        }

        if (dynamicactions.action.Target == 'afmlimit9') {
            ValidatorHelper.AFMValidator.OnUpdateInputValueValidation(evt);
        }
        if (dynamicactions.action.Target == 'aeriolimit14') {
            // if ((evt.target.value).length > 9) {
            //     evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            // }
            if ((evt.target.value).length > 14) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }

        }


        let energyKind = this.state.selectedType
        let secondReceiver = this.state.SupplierStr2
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];

        function checkifFilled(item) {
            if (item.data.Data !== '') {

                if (item.action.Name == 'Αριθμός Παροχής*') {
                    if (energyKind != 2 && (evt.target.value).length < 8) {
                        return false;
                    }
                    else if (energyKind == 2 && (((evt.target.value).length < 11) || (evt.target.value).length > 11)) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.action.Target == 'afmlimit9') {
                    console.log(item.data.Data)
                    if (/^\d{9}$/.test(item.data.Data) == false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.action.Target == 'aeriolimit14') {
                    console.log(item.data.Data)
                    if (/^\d{14}$/.test(item.data.Data) == false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else
                    return true;

            }
        }
        // console.log(res);

        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '' && element.action.Required) {
                        this.setSubmitButtonsEnabled(false);
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    this.setState({ areAllFieldsFilled: true }, () => {
                        this.setSubmitButtonsEnabled(true);
                    });
                }
                else {
                    this.setSubmitButtonsEnabled(false);
                }
                // if (dynamicactions.action.Target == 'minimum8') {
                //     if ((evt.target.value).length < 8) {
                //         document.getElementById('submit-button-1').setAttribute('disabled', '');
                //         document.getElementById('submit-button-2').setAttribute('disabled', '');
                //     }
                // }
            }
        }
    }

    updateInputValueCascader(value) {
        var res = (document.getElementsByClassName("dropdown")[2].innerHTML.substring(77, 150)).split("/");

        let temp = '';
        var i;
        for (i = 0; i < value.length; i++) {
            temp += value[i] + ' / ';
        }

        this.setState({ instanceCategory: temp });
        // console.log(temp);

        let data = [...this.state.steps];
        function checkifFilled(item) {
            return item.data.Data !== '';
        }
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = temp;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '' && element.action.Required) {
                        this.setSubmitButtonsEnabled(false);
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    this.setState({ areAllFieldsFilled: true }, () => {
                        this.setSubmitButtonsEnabled(true);
                    });
                }
            }
        }
    }

    submitInstance = stepStatus => {
        console.log(this.state.wId);

        if (localStorage.getItem('token')) {
            if (this.state.selectedReceiver == 3) {
                fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'selectedType': this.state.selectedType,
                        'selectedReceiver': this.state.selectedReceiver,
                        'selectedSupplier': this.state.SupplierStr,
                        'workflow': this.state.wId,
                        'instanceId': this.state.instanceId,
                        'cellPhoneNumber': this.state.phoneNumber,
                        'landLineNumber': this.state.landLineNumber,
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/submit/' + data.Id + '/', {
                            method: 'POST',
                            headers: new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            }),
                            body: JSON.stringify(this.state.steps[stepStatus - 1])
                        })
                            .then(data => {

                                fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/new/', {
                                    method: 'POST',
                                    headers: new Headers({
                                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    }),
                                    body: JSON.stringify({
                                        'selectedType': this.state.selectedType,
                                        'selectedReceiver': this.state.selectedReceiver,
                                        'selectedSupplier': this.state.SupplierStr2,
                                        'workflow': this.state.wId,
                                        'instanceId': this.state.instanceId2,
                                        'cellPhoneNumber': this.state.phoneNumber,
                                        'landLineNumber': this.state.landLineNumber,
                                    })
                                })
                                    .then(res => res.json())
                                    .then(data => {
                                        this.setState({ serialNo: data.SerialNo });
                                        fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/submit/' + data.Id + '/', {
                                            method: 'POST',
                                            headers: new Headers({
                                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                            }),
                                            body: JSON.stringify(this.state.steps[stepStatus - 1])
                                        })
                                            .then(data => {

                                                window.location.replace("/consumer/instance/" + this.state.instanceId);
                                            })
                                    })
                            })
                    })
            }
            else {
                fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'selectedType': this.state.selectedType,
                        'selectedReceiver': this.state.selectedReceiver,
                        'selectedSupplier': this.state.SupplierStr,
                        'workflow': this.state.wId,
                        'instanceId': this.state.instanceId,
                        'cellPhoneNumber': this.state.phoneNumber,
                        'landLineNumber': this.state.landLineNumber,
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        this.setState({ serialNo: data.SerialNo });
                        fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/submit/' + data.Id + '/', {
                            method: 'POST',
                            headers: new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            }),
                            body: JSON.stringify(this.state.steps[stepStatus - 1])
                        })
                            .then(data => {
                                window.location.replace("/consumer/instance/" + this.state.instanceId);

                            })
                    })
            }
        }
    }

    render() {

        return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{ background: "linear-gradient(to right, #FFFFFF, #4A002B)" }}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold mb-0">
                                                        Υποβολή Νέου Αιτήματος - Παραπόνου
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>


                <Container className="mt-4" fluid>
                    {this.state.dropdownsActive == 1 ?
                        <Row className="mt-5">
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                Υποβολή Νέου Αιτήματος - Παραπόνου
                                            </Col>
                                            <Col className="text-right" xs="4">
                                                {this.state.submitButton == 1 ?
                                                    <Button
                                                        color="success"
                                                        onClick={() => { this.handleClick() }}
                                                        size="sm"
                                                    >
                                                        Συνέχεια <i className="fas fa-chevron-right" />
                                                    </Button> :
                                                    <p></p>
                                                }
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="consumer-client"
                                                            >
                                                                Κατηγορία αιτήματος
                                                            </label>
                                                            &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-katigoria-aitimatos'} aria-hidden="true"></i>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="consumer-client"
                                                                type="select"
                                                                onChange={this.changeType}
                                                            >
                                                                <option value='0'>-- Επιλέξτε κατηγορία αιτήματος --</option>
                                                                <option value='1'>Φυσικό Αέριο</option>
                                                                <option value='2'>Ηλεκτρική Ενέργεια</option>
                                                                <option value='3'>Ηλεκτρική Ενέργεια και Φυσικό Αέριο</option>
                                                            </Input>
                                                            <ReactTooltip id={'info-katigoria-aitimatos'} type='info'>
                                                                <span>Επιλέξτε κατηγορία αιτήματος</span>
                                                            </ReactTooltip>
                                                        </FormGroup>
                                                    </Col>

                                                    {this.state.selectedType == 3 ?
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="consumer-user-home"
                                                                >
                                                                    Κατηγορία Παραλήπτη Αιτήματος
                                                                </label>
                                                                &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-paraliptis-aitimatos'} aria-hidden="true"></i>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="consumer-user-home"
                                                                    placeholder="Προμηθευτής Ενέργειας"
                                                                    type="text"
                                                                    disabled={true}
                                                                />
                                                                <ReactTooltip id={'info-paraliptis-aitimatos'} type='info'>
                                                                    <span>Επιλέξτε αν θέλετε να απευθυνθείτε <br />σε διαχειριστή ή προμηθευτή. <br />Εάν στο προηγούμενο πεδίο <br />έχετε επιλέξει αίτημα που αφορά <br />σε "Ηλεκτρική Ενέργεια και Φυσικό Αέριο", <br />τότε το παρόν πεδίο θα κλειδώσει <br />στην επιλογή "Προμηθευτής Ενέργειας".</span>
                                                                </ReactTooltip>
                                                            </FormGroup>
                                                        </Col>
                                                        :
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="consumer-user-home"
                                                                >
                                                                    Κατηγορία Παραλήπτη Αιτήματος
                                                                </label>
                                                                &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-paraliptis-aitimatos'} aria-hidden="true"></i>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="consumer-user-home"
                                                                    type="select"
                                                                    onChange={this.changeReceiver}
                                                                >
                                                                    <option value='0'>-- Επιλέξτε κατηγορία παραλήπτη --</option>
                                                                    <option value='1'>Διαχειριστής Δικτύου</option>
                                                                    <option value='2'>Προμηθευτής Ενέργειας</option>
                                                                    <option value='3'>Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας</option>
                                                                </Input>
                                                                <ReactTooltip id={'info-paraliptis-aitimatos'} type='info'>
                                                                    <span>Επιλέξτε αν θέλετε να απευθυνθείτε <br />σε διαχειριστή, προμηθευτή ή παράλληλα και στους δύο. <br />Εάν στο προηγούμενο πεδίο <br />έχετε επιλέξει αίτημα που αφορά <br />σε "Ηλεκτρική Ενέργεια και Φυσικό Αέριο", <br />τότε το παρόν πεδίο θα κλειδώσει <br />στην επιλογή "Προμηθευτής Ενέργειας".</span>
                                                                </ReactTooltip>
                                                            </FormGroup>
                                                        </Col>}

                                                </Row>

                                                {this.state.selectedType == 1 && this.state.selectedReceiver == 1 ?
                                                    <>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-use-commercial"
                                                                    >
                                                                        Επιλογή διαχειριστή (Φυσικό Αέριο)
                                                                    </label>
                                                                    &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-use-commercial'} aria-hidden="true"></i>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        defaultValue="Lucky"
                                                                        id="consumer-use-commercial"
                                                                        type="select"
                                                                        onChange={this.changeSupplier}
                                                                    >
                                                                        <option value='0'>-- Επιλέξτε διαχειριστή --</option>
                                                                        {this.state.user.Email.includes('@rae.gr') ?
                                                                            <>
                                                                                {
                                                                                    this.state.gasMaintainers.map((dynamicData) =>
                                                                                        <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                    )}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.gasMaintainers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                        <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                    )}</>}

                                                                    </Input>
                                                                    <ReactTooltip id={'info-consumer-use-commercial'} type='info'>
                                                                        <span>Επιλέξτε διαχειριστή</span>
                                                                    </ReactTooltip>
                                                                </FormGroup>
                                                            </Col>

                                                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                            </div>
                                                        </Row>
                                                    </>
                                                    : <> {this.state.selectedType == 1 && this.state.selectedReceiver == 2 ?
                                                        <>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="consumer-user-home"
                                                                        >
                                                                            Επιλογή προμηθευτή (Φυσικό Αέριο)
                                                                        </label>
                                                                        &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-user-home-fysiko-aerio'} aria-hidden="true"></i>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="consumer-user-home"
                                                                            type="select"
                                                                            onChange={this.changeSupplier}
                                                                        >
                                                                            <option value='0'>-- Επιλέξτε προμηθευτή --</option>
                                                                            {this.state.user.Email.includes('@rae.gr') ?
                                                                                <>
                                                                                    {
                                                                                        this.state.gasSuppliers.map((dynamicData) =>
                                                                                            <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                        )}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        this.state.gasSuppliers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                            <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                        )}</>}
                                                                        </Input>
                                                                        <ReactTooltip id={'info-consumer-user-home-fysiko-aerio'} type='info'>
                                                                            <span>Επιλέξτε προμηθευτή</span>
                                                                        </ReactTooltip>
                                                                    </FormGroup>
                                                                </Col>

                                                                <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                    <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                                </div>
                                                            </Row>
                                                        </> : <></>
                                                    }
                                                        {this.state.selectedType == 1 && this.state.selectedReceiver == 3 ?
                                                            <>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="consumer-use-commercial"
                                                                            >
                                                                                Επιλογή διαχειριστή (Φυσικό Αέριο)
                                                                            </label>
                                                                            &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-use-commercial'} aria-hidden="true"></i>
                                                                            <Input
                                                                                className="form-control-alternative"
                                                                                defaultValue="Lucky"
                                                                                id="consumer-use-commercial"
                                                                                type="select"
                                                                                onChange={this.changeSupplier}
                                                                            >
                                                                                <option value='0'>-- Επιλέξτε διαχειριστή --</option>
                                                                                {this.state.user.Email.includes('@rae.gr') ?
                                                                                    <>
                                                                                        {
                                                                                            this.state.gasMaintainers.map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            this.state.gasMaintainers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}</>}
                                                                            </Input>
                                                                            <ReactTooltip id={'info-consumer-use-commercial'} type='info'>
                                                                                <span>Επιλέξτε διαχειριστή</span>
                                                                            </ReactTooltip>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="consumer-user-home"
                                                                            >
                                                                                Επιλογή προμηθευτή (Φυσικό Αέριο)
                                                                            </label>
                                                                            &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-user-home-fysiko-aerio'} aria-hidden="true"></i>
                                                                            <Input
                                                                                className="form-control-alternative"
                                                                                id="consumer-user-home"
                                                                                type="select"
                                                                                onChange={this.changeSupplier2}
                                                                            >
                                                                                <option value='0'>-- Επιλέξτε προμηθευτή --</option>
                                                                                {this.state.user.Email.includes('@rae.gr') ?
                                                                                    <>
                                                                                        {
                                                                                            this.state.gasSuppliers.map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            this.state.gasSuppliers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}</>}
                                                                            </Input>
                                                                            <ReactTooltip id={'info-consumer-user-home-fysiko-aerio'} type='info'>
                                                                                <span>Επιλέξτε προμηθευτή</span>
                                                                            </ReactTooltip>
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                        <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                                    </div>
                                                                </Row>
                                                            </> : <></>}
                                                    </>
                                                }

                                                {this.state.selectedType == 2 && this.state.selectedReceiver == 1 ?
                                                    <>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-use-commercial"
                                                                    >
                                                                        Επιλογή διαχειριστή (Ηλεκτρική Ενέργεια)
                                                                    </label>
                                                                    &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-use-commercial-hlektrikh-diaxeiristis'} aria-hidden="true"></i>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        defaultValue="Lucky"
                                                                        id="consumer-use-commercial"
                                                                        type="select"
                                                                        onChange={this.changeSupplier}
                                                                    >
                                                                        <option value='0'>-- Επιλέξτε διαχειριστή --</option>
                                                                        {this.state.user.Email.includes('@rae.gr') ?
                                                                            <>
                                                                                {
                                                                                    this.state.electrMaintainers.map((dynamicData) =>
                                                                                        <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                    )}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.electrMaintainers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                        <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                    )}</>}
                                                                    </Input>
                                                                    <ReactTooltip id={'info-consumer-use-commercial-hlektrikh-diaxeiristis'} type='info'>
                                                                        <span>Επιλέξτε διαχειριστή</span>
                                                                    </ReactTooltip>
                                                                </FormGroup>
                                                            </Col>

                                                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                            </div>
                                                        </Row>
                                                    </>
                                                    : <> {this.state.selectedType == 2 && this.state.selectedReceiver == 2 ?
                                                        <>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="consumer-user-home"
                                                                        >
                                                                            Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια)
                                                                        </label>
                                                                        &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-user-home-hlektrikh-diaxeiristis'} aria-hidden="true"></i>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="consumer-user-home"
                                                                            type="select"
                                                                            onChange={this.changeSupplier}
                                                                        >
                                                                            <option value='0'>-- Επιλέξτε προμηθευτή --</option>
                                                                            {this.state.user.Email.includes('@rae.gr') ?
                                                                                <>
                                                                                    {
                                                                                        this.state.electrSuppliers.map((dynamicData) =>
                                                                                            <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                        )}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        this.state.electrSuppliers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                            <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                        )}</>}
                                                                        </Input>
                                                                        <ReactTooltip id={'info-consumer-user-home-hlektrikh-diaxeiristis'} type='info'>
                                                                            <span>Επιλέξτε προμηθευτή</span>
                                                                        </ReactTooltip>
                                                                    </FormGroup>
                                                                </Col>

                                                                <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                    <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                                </div>
                                                            </Row>
                                                        </> : <></>
                                                    }
                                                        {this.state.selectedType == 2 && this.state.selectedReceiver == 3 ?
                                                            <>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="consumer-use-commercial"
                                                                            >
                                                                                Επιλογή διαχειριστή (Ηλεκτρική Ενέργεια)
                                                                            </label>
                                                                            &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-use-commercial-hlektrikh-diaxeiristis'} aria-hidden="true"></i>
                                                                            <Input
                                                                                className="form-control-alternative"
                                                                                defaultValue="Lucky"
                                                                                id="consumer-use-commercial"
                                                                                type="select"
                                                                                onChange={this.changeSupplier}
                                                                            >
                                                                                <option value='0'>-- Επιλέξτε διαχειριστή --</option>
                                                                                {this.state.user.Email.includes('@rae.gr') ?
                                                                                    <>
                                                                                        {
                                                                                            this.state.electrMaintainers.map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            this.state.electrMaintainers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}</>}
                                                                            </Input>
                                                                            <ReactTooltip id={'info-consumer-use-commercial-hlektrikh-diaxeiristis'} type='info'>
                                                                                <span>Επιλέξτε διαχειριστή</span>
                                                                            </ReactTooltip>
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="consumer-user-home"
                                                                            >
                                                                                Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια)
                                                                            </label>
                                                                            &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-user-home-hlektrikh-diaxeiristis'} aria-hidden="true"></i>
                                                                            <Input
                                                                                className="form-control-alternative"
                                                                                id="consumer-user-home"
                                                                                type="select"
                                                                                onChange={this.changeSupplier2}
                                                                            >
                                                                                <option value='0'>-- Επιλέξτε προμηθευτή --</option>
                                                                                {this.state.user.Email.includes('@rae.gr') ?
                                                                                    <>
                                                                                        {
                                                                                            this.state.electrSuppliers.map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            this.state.electrSuppliers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                                <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                            )}</>}
                                                                            </Input>
                                                                            <ReactTooltip id={'info-consumer-user-home-hlektrikh-diaxeiristis'} type='info'>
                                                                                <span>Επιλέξτε προμηθευτή</span>
                                                                            </ReactTooltip>
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                        <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                                    </div>
                                                                </Row>
                                                            </> : <></>}
                                                    </>
                                                }

                                                {this.state.selectedType == 3 ?
                                                    <>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-user-home"
                                                                    >
                                                                        Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια και Φυσικό Αέριο)
                                                                    </label>
                                                                    &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-consumer-user-home-fysiko-aerio'} aria-hidden="true"></i>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="consumer-user-home"
                                                                        type="select"
                                                                        onChange={this.changeSupplier}
                                                                    >
                                                                        <option value='0'>-- Επιλέξτε προμηθευτή --</option>
                                                                        {this.state.user.Email.includes('@rae.gr') ?
                                                                            <>
                                                                                {
                                                                                    this.state.gasAndElectrSuppliers.map((dynamicData) =>
                                                                                        <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                    )}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.gasAndElectrSuppliers.filter(dynamicData => dynamicData.Name !== 'Δοκιμαστικός Παραλήπτης').map((dynamicData) =>
                                                                                        <option value={dynamicData.Name}>{dynamicData.Name}</option>
                                                                                    )}</>}
                                                                    </Input>
                                                                    <ReactTooltip id={'info-consumer-user-home-fysiko-aerio'} type='info'>
                                                                        <span>Επιλέξτε προμηθευτή</span>
                                                                    </ReactTooltip>
                                                                </FormGroup>
                                                            </Col>

                                                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                <p>Με την υποβολή του αιτήματος, συμφωνείτε με τους <a href="https://www.rae.gr/myraeterms/">Όρους και τις Προϋποθέσεις</a></p>
                                                            </div>
                                                        </Row>
                                                    </>
                                                    : <></>}
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <>
                            {this.state.steps.map((dynamicData) =>
                                <>
                                    {dynamicData.step.Status == 1 ?
                                        <>
                                            {dynamicData.step.Conditional ?
                                                <Container className="mt-2" fluid>
                                                    <Row className="mt-5">
                                                        <Col className="order-xl-1" xl="12">
                                                            <Card className="bg-secondary shadow">
                                                                <CardHeader className="bg-white border-0">
                                                                    <Row className="align-items-center">
                                                                        <Col xs="8">
                                                                            <h3 className="mb-0">{dynamicData.step.Name}</h3>
                                                                        </Col>
                                                                        <Col className="text-right" xs="4">
                                                                            <Button
                                                                                color="danger"
                                                                                onClick={() => { this.deleteInstance() }}
                                                                                size="sm"
                                                                            >
                                                                                Ακύρωση
                                                                            </Button>
                                                                            <Button
                                                                                color="success"
                                                                                onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                                size="sm"
                                                                                id="submit-button-1"
                                                                            >
                                                                                Υποβολή
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Form>
                                                                        <div className="pl-lg-4">
                                                                            <Row>
                                                                                {dynamicData.actions.map((dynamicActions) =>
                                                                                    <>
                                                                                        {dynamicActions.action.RunTime == 'consumer' ?
                                                                                            <Col lg="12">
                                                                                                <FormGroup>
                                                                                                    <label
                                                                                                        className="form-control-label"
                                                                                                        htmlFor="input-username"
                                                                                                    >
                                                                                                        {dynamicActions.action.Name}
                                                                                                    </label>
                                                                                                    {/* </>} */}
                                                                                                    {dynamicActions.action.Type == 'file' ?
                                                                                                        <>
                                                                                                            <Row>
                                                                                                                <Col lg="10">
                                                                                                                    <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
                                                                                                                        Επιλογή αρχείου
                                                                                                                    </label>
                                                                                                                    &nbsp;
                                                                                                                    <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                    <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                        <span>Μέγιστο μέγεθος αρχείου 5ΜΒ.</span>
                                                                                                                        <div id="imageattooltip"></div>
                                                                                                                    </ReactTooltip>
                                                                                                                    <Input
                                                                                                                        className="form-control-alternative"
                                                                                                                        // defaultValue={ dynamicActions.data.Data }
                                                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                        // placeholder={dynamicActions.action.Description}
                                                                                                                        style={{ visibility: "hidden" }}
                                                                                                                        type={dynamicActions.action.Type}
                                                                                                                        disabled={!dynamicActions.action.Required}
                                                                                                                        onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            </Row>

                                                                                                            <Table className="align-items-center table-flush" responsive>
                                                                                                                <thead className="thead-light">
                                                                                                                    <tr>
                                                                                                                        <th scope="col">Ονομα Αρχειου</th>
                                                                                                                        <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                                                                        <th scope="col">Ενεργειες</th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        this.state.files.map((dynamicFiles) =>
                                                                                                                            <>
                                                                                                                                {dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                                                                                    <>
                                                                                                                                        <tr>
                                                                                                                                            <td>{dynamicFiles.FileName}</td>
                                                                                                                                            <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                                                                                                                            <td>
                                                                                                                                                <Button
                                                                                                                                                    color="primary"
                                                                                                                                                    href={dynamicFiles.Link}
                                                                                                                                                    target="_blank"
                                                                                                                                                    size="sm"
                                                                                                                                                    title="Προβολή"
                                                                                                                                                >
                                                                                                                                                    <i className="fas fa-eye" />
                                                                                                                                                </Button>
                                                                                                                                                {console.log(dynamicFiles)}
                                                                                                                                                <Button
                                                                                                                                                    color="danger"
                                                                                                                                                    onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                                                                                    size="sm"
                                                                                                                                                    title="Διαγραφή"
                                                                                                                                                >
                                                                                                                                                    <i className="fas fa-trash" />
                                                                                                                                                </Button>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </> : <></>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                </tbody>
                                                                                                            </Table>
                                                                                                        </>
                                                                                                        : <>{dynamicActions.action.Type == 'button' ?
                                                                                                            <>
                                                                                                                <Col lg="6">
                                                                                                                    <Button
                                                                                                                        color="primary"
                                                                                                                        onClick={evt => this.dynamicCall(dynamicActions.action.Target)}
                                                                                                                        size="sm"
                                                                                                                    >
                                                                                                                        {dynamicActions.action.Description}
                                                                                                                    </Button>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                            : <>{dynamicActions.action.Type == 'textarea' ?
                                                                                                                <React.Fragment>
                                                                                                                    &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                    <ReactTooltip id={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                        <span>{dynamicActions.action.Description}</span>
                                                                                                                    </ReactTooltip>
                                                                                                                    <Input
                                                                                                                        className="form-control-alternative"
                                                                                                                        defaultValue={dynamicActions.data.Data}
                                                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                        placeholder={dynamicActions.action.Description}
                                                                                                                        type='textarea'
                                                                                                                        disabled={!dynamicActions.action.Required}
                                                                                                                        onChange={evt => this.updateInputValue(evt)}
                                                                                                                    />
                                                                                                                </React.Fragment>

                                                                                                                : <> {dynamicActions.action.Type == 'number' ?
                                                                                                                    <React.Fragment>
                                                                                                                        <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>

                                                                                                                        <EVOControlsHelper.InputInteger
                                                                                                                            name={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                            limitType={dynamicActions.action.Target}
                                                                                                                            className="form-control-alternative"
                                                                                                                            defaultValue={dynamicActions.data.Data}
                                                                                                                            id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                            placeholder={dynamicActions.action.Description}
                                                                                                                            type={dynamicActions.action.Type}
                                                                                                                            disabled={!dynamicActions.action.Required}
                                                                                                                            onChange={evt => this.updateInputValueNumber(evt, dynamicActions)}
                                                                                                                        />
                                                                                                                        {dynamicActions.action.Name == 'Αριθμός Παροχής*' && this.state.selectedType == 2 ?
                                                                                                                            <>
                                                                                                                                <CardBody>
                                                                                                                                    <p style={{ color: 'orange', marginLeft: '-20px', marginTop: '-20px', marginBottom: '-30px' }}><strong>Παρακαλούμε συμπληρώστε ορθά το πεδίο του Αριθμού Παροχής με 11 Ψηφία</strong></p>
                                                                                                                                </CardBody>
                                                                                                                            </>
                                                                                                                            : <></>}
                                                                                                                        {dynamicActions.action.Name == 'Αριθμός Παροχής*' && this.state.selectedType != 2 ?
                                                                                                                            <>
                                                                                                                                <CardBody>
                                                                                                                                    <p style={{ color: 'orange', marginLeft: '-20px', marginTop: '-20px', marginBottom: '-30px' }}><strong>Παρακαλούμε συμπληρώστε ορθά το πεδίο του Αριθμού Παροχής με 8-14 Ψηφία</strong></p>
                                                                                                                                </CardBody>
                                                                                                                            </>
                                                                                                                            : <></>}
                                                                                                                        {/* <Input
                                                                                                                            className="form-control-alternative"
                                                                                                                            defaultValue={dynamicActions.data.Data}
                                                                                                                            id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                            placeholder={dynamicActions.action.Description}
                                                                                                                            type={dynamicActions.action.Type}
                                                                                                                            disabled={!dynamicActions.action.Required}
                                                                                                                            onChange={evt => this.updateInputValueNumber(evt, dynamicActions)}

                                                                                                                        /> */}

                                                                                                                        <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                            <span>{dynamicActions.action.Description}</span>
                                                                                                                            {dynamicActions.action.Description == 'Αριθμός Παροχής' ?
                                                                                                                                <>
                                                                                                                                    <div id="imageattooltip"></div>Συμπληρώστε τον αριθμό παροχής που φαίνεται στον λογαριασμό, χωρίς κενά και χωρίς παύλες.
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                </>

                                                                                                                            }

                                                                                                                        </ReactTooltip>

                                                                                                                    </React.Fragment>

                                                                                                                    : <> {dynamicActions.action.Type == 'dropdown' ?

                                                                                                                        <React.Fragment>
                                                                                                                            <i class="fa fa-info-circle" data-tip data-for={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                            <Row>
                                                                                                                                <Col lg="10">
                                                                                                                                    <ReactTooltip id={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                                        <span>{dynamicActions.action.Description}</span>

                                                                                                                                        {/* <p>Δοκιμαστικό κείμενο</p> */}
                                                                                                                                    </ReactTooltip>
                                                                                                                                    <Cascader id={(dynamicData.step.Id + "/" + dynamicActions.action.Id)} className={"dropdown"} options={JSON.parse(dynamicActions.action.Target)} onChange={this.updateInputValueCascader} placeholder={"Επιλέξτε κατηγορία"} style={{ width: '100%' }} />
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </React.Fragment>
                                                                                                                        :
                                                                                                                        <React.Fragment>
                                                                                                                            <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                            <Input
                                                                                                                                className="form-control-alternative"
                                                                                                                                defaultValue={dynamicActions.data.Data}
                                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                                placeholder={dynamicActions.action.Description}
                                                                                                                                type={dynamicActions.action.Type}
                                                                                                                                disabled={!dynamicActions.action.Required}
                                                                                                                                onChange={evt => this.updateInputValue(evt)}

                                                                                                                            />


                                                                                                                            <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                                <span>{dynamicActions.action.Description}</span>

                                                                                                                                {/* <div id="imageattooltip"></div> */}
                                                                                                                            </ReactTooltip>

                                                                                                                        </React.Fragment>
                                                                                                                    }
                                                                                                                    </>
                                                                                                                }
                                                                                                                </>
                                                                                                            }
                                                                                                            </>
                                                                                                        }</>
                                                                                                    }
                                                                                                </FormGroup>

                                                                                            </Col>
                                                                                            :
                                                                                            <> </>
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                            </Row>
                                                                        </div>
                                                                    </Form>
                                                                    <div style={{ paddingLeft: '23px' }}>
                                                                        <FormGroup>
                                                                            <div className="form-group">
                                                                                <label className="form-control-label" htmlFor="input-phonenumber">Κινητό Τηλέφωνο</label>
                                                                                <PhoneInput
                                                                                    id="input-phonenumber"
                                                                                    country={'gr'}
                                                                                    value={this.state.country_code}
                                                                                    onChange={this.changePhoneNumber}
                                                                                    placeholder="69ΧΧΧΧΧΧΧΧ"
                                                                                    inputClass="form-control-alternative"
                                                                                    containerClass="phone-input-container"
                                                                                    enableSearch={true}
                                                                                    style={{ maxWidth: '400px' }}
                                                                                />
                                                                            </div>
                                                                            <span id={"input-phonenumber-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label for="landLineNumber">Σταθερό Τηλέφωνο</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="landLineNumber"
                                                                                id="landLineNumber"
                                                                                value={this.state.landLineNumber}
                                                                                onChange={this.handleInputChange}
                                                                                placeholder="Αριθμός σταθερού τηλεφώνου"
                                                                                style={{ maxWidth: '400px' }}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </CardBody>
                                                                <CardFooter className="bg-white border-0">
                                                                    <Row className="align-items-center">
                                                                        <Col xs="8">
                                                                            {/* <h3 className="mb-0">{dynamicData.step.Name}</h3> */}
                                                                        </Col>
                                                                        <Col className="text-right" xs="4">
                                                                            <Button
                                                                                color="danger"
                                                                                onClick={() => { this.deleteInstance() }}
                                                                                size="sm"
                                                                            >
                                                                                Ακύρωση
                                                                            </Button>
                                                                            <Button
                                                                                color="success"
                                                                                onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                                size="sm"
                                                                                id="submit-button-2"
                                                                            >
                                                                                Υποβολή
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </CardFooter>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                                : <Container className="mt-2" fluid>
                                                    <Row className="mt-5">
                                                        <Col className="order-xl-1" xl="12">
                                                            <Card className="bg-secondary shadow">
                                                                <CardBody>
                                                                    <Form>
                                                                        <div className="pl-lg-4">
                                                                            <Row>
                                                                                <Col lg="12">
                                                                                    <p style={{ fontSize: '18px' }}>Θα ενημερωθείτε από τον Έλεγχο Πορείας για το επόμενο στάδιο της αίτησης που θα χρειαστεί να συμπληρώσετε.</p>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        href={"/consumer/instance/" + this.state.instance.Id}
                                                                                        size="lg"
                                                                                    >
                                                                                        Έλεγχος πορείας
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Form>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            }
                                        </>
                                        :
                                        <> </>
                                    }
                                </>
                            )}
                        </>
                    }
                </Container>
            </>
        );
    }
}

export default newInstance;
