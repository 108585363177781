import React, { useState } from 'react';
import { FormGroup, Row, Col, Collapse, CardBody, Card, Button } from 'reactstrap';
import ButtonActionComponent from './ButtonActionComponent';
import TextareaActionComponent from './TextAreaActionComponent';
import NumberActionComponent from './NumberActionComponent';
import DropdownActionComponent from './DropdownActionComponent';
import DefaultActionComponent from './DefaultActionComponent';
import FileActionComponent from './FileActionComponent';

// Helper function to chunk array into sections based on Order
function chunkIntoSections(actions) {
    const sections = [{ order: 1, title: 'Επιπλέον Ατομικά Στοιχεία' }, { order: 4, title: 'Στοιχεία Εξουσιοδοτημένου Προσώπου' }, { order: 10, title: 'Παράλληλες ενέργειες για το ίδιο θέμα' }, { order: 12, title: 'Επισυναπτόμενα Έγγραφα' }, { order: 16, title: 'Περιγραφή Παραπόνου' }];
    const chunks = sections.map((section, i) => {
        let nextOrder = sections[i + 1]?.order || Infinity;
        return {
            title: section.title,
            actions: actions.filter(action => action.action.Order >= section.order && action.action.Order < nextOrder)
        };
    });
    return chunks;
}

const DynamicActionsComponent = ({ dynamicData, updateInputValue, updateInputValueNumber, updateInputValueCascader, uploadFile, deleteFile, files, onCheckboxChange }) => {
    const sections = chunkIntoSections(dynamicData.actions);
    const [openSections, setOpenSections] = useState(sections.map(() => false));
    const [isChecked, setChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        const checkedStatus = event.target.checked;
        setChecked(checkedStatus);

        // Inform the parent component of the change
        onCheckboxChange(checkedStatus);
    };

    const toggleSection = index => {
        setOpenSections(prevState => prevState.map((isOpen, i) => i === index ? !isOpen : isOpen));
    };

    return (
        <div>
            <Row>
                <Col xl="12">
                    {sections.map((section, index) => (
                        <Card>
                            <CardBody>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h2 style={{ color: '#26575F' }}>{section.title}</h2>
                                    <Button size='sm' onClick={(e) => { e.preventDefault(); toggleSection(index); }}>
                                        {openSections[index] ? 'Απόκρυψη' : 'Προβολή'}
                                    </Button>
                                </div>
                                <Collapse isOpen={openSections[index]}>
                                    <hr />
                                    {section.actions.map(dynamicActions => (
                                        <FormGroup key={dynamicActions.action.Id}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                                style={(index !== 2 && index !== 3) ? { paddingLeft: '14px' } : {}}
                                            >
                                                {dynamicActions.action.Name}
                                            </label>

                                            {dynamicActions.action.Type === 'textarea' && <TextareaActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValue={updateInputValue} />}
                                            {dynamicActions.action.Type === 'number' && <NumberActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValueNumber={updateInputValueNumber} />}
                                            {dynamicActions.action.Type === 'dropdown' && <DropdownActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValueCascader={updateInputValueCascader} />}
                                            {dynamicActions.action.Type === 'file' && <FileActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} uploadFile={uploadFile} deleteFile={deleteFile} files={files} />}
                                            {(!['button', 'textarea', 'number', 'dropdown', 'file'].includes(dynamicActions.action.Type)) && <DefaultActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValue={updateInputValue} />}
                                        </FormGroup>
                                    ))}
                                </Collapse>
                            </CardBody>
                        </Card>

                    ))}
                </Col>
            </Row>
            <br />
            <Row>
                <Col xl="12">
                    <ul style={{ listStyleType: 'circle' }}>
                        <li>Δηλώνω ότι το περιεχόμενο της αίτησης και των συνυποβαλλόμενων εγγράφων είναι αληθές και ότι συναινώ να λαμβάνω ενημέρωση για την υπόθεση μου, μέσω της πλατφόρμας ΕΛΕΝΔΙΑ, ή σε οποιαδήποτε από τις δηλωθείσες ανωτέρω διευθύνσεις (ταχυδρομική, e-mail) και τηλέφωνα.</li>
                        <li>Συμφωνώ να εξετάσει ο ΕΛΕΝΔΙΑ την εν λόγω διαφορά σύμφωνα με τα αναφερόμενα στον ιστότοπο www.myrae.elendia.gr των οποίων δηλώνω ότι έλαβα γνώση</li>
                        <li>Εξουσιοδοτώ τον ΕΛΕΝΔΙΑ, στο πλαίσιο εξέτασης της διαφοράς, αντ' εμού και για λογαριασμό μου, να αιτέιται και λαμβάνει από τον αναφερόμενο πάροχο τα αναγκαία κατά περίπτωση δεδομένα προσωπικού χαρακτήρα και στοιχεία που καλύπτονται από το προσωπικό απόρρητο, τα οποία με αφορούν, όπως ενδεικτικά αντίγραφα συμβάσεων, κινήσεις λογαριασμών, παραστατικά συναλλαγών, για τη χορήγηση των οποίων παρέχω ρητά τη συγκατάθεση μου. Επίσης εξουσιοδοτώ τον ΕΛΕΝΔΙΑ να παρέχει τις σχετικές με την υπόθεση μου πληροφορίες και έγγραφα στον αναφερόμενο πάροχο μου</li>
                    </ul>
                    <div
                        style={{
                            backgroundColor: '#e6f2ff',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                        }}
                    >
                        <p>Μελέτησα την ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΔΕΔΟΜΕΝΩΝ ΠΡΟΣΩΠΙΚΟΥ ΧΑΡΑΚΤΗΡΑ ΕΛΕΝΔΙΑ που είναι αναρτημένη στον ιστότοπο www.myrae.elendia.gr και δηλώνω ότι την αποδέχομαι και ΣΥΝΑΙΝΩ στην επεξεργασία των δεδομένων μου προσωπικού χαρακτήρα από τον ΕΛΕΝΔΙΑ.</p>
                        <label>
                            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} /> ΑΠΟΔΕΧΟΜΑΙ και ΣΥΝΑΙΝΩ
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DynamicActionsComponent;
